import bgLeft from 'assets/img/bg/bg-left-23.png';
import bgRight from 'assets/img/bg/bg-right-23.png';
import Button from 'components/base/Button';  
import { Col, Row, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
const bluishColor = '#a3d7ff'; // Color between #60d5ff and white
const TeamSection = () => {
  return (
    <section id="whatsnew" className="alternate-landing-team position-relative" style={{ marginBottom: '0', padding: '20px 0' }}>
      <div
        className="position-absolute w-100 h-100 start-0 end-0 top-0 bg-body dark__bg-gray-1000"
        style={{ transform: 'skewY(-6deg)', transformOrigin: 'right' }}
      />
      <div
        className="bg-holder d-none d-xl-block position-absolute top-0 start-0 end-0"
        style={{
          backgroundImage: `url(${bgLeft})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'left center',
          height: '100%'
        }}
      />
      <div
        className="bg-holder d-none d-xl-block position-absolute top-0 start-0 end-0"
        style={{
          backgroundImage: `url(${bgRight})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'right center',
          height: '100%'
        }}
      />
      <div className="container-small position-relative z-index-1" style={{ padding: '0', marginTop: '20px' }}>
        <div className="text-center mb-4">
          <h1 className="text-gradient-info mt-4 mb-4">What's New</h1>
        </div>
        
      </div>
      <Container>
        <h2 className="text-light text-center mb-10">Stay updated with Hazbot’s latest news, releases, and industry insights.</h2>
        <Row className="justify-content-center align-items-stretch">
          {/* Column 1 */}
          <Col lg={4} md={6} className="mb-4">
            <div
              className="d-flex flex-column position-relative text-start"
              style={{
                border: '2px solid #60d5ff',
                borderRadius: '15px',
                backgroundColor: '#0f111a',
                color: '#ffffff',
                height: '100%',
                padding: '10px',
                boxSizing: 'border-box'
              }}
            >
              {/* Title Section */}
              <div style={{ margin: '0 0 10px', flex: '0 1 auto' }}>
                <h5 style={{ margin: '0', fontWeight: 'bold', color: bluishColor }}>
                  Hazbot V1.1.2 - Ready for Testing!
                </h5>
                <div
                  style={{
                    height: '2px',
                    backgroundColor: '#60d5ff',
                    marginTop: '10px', // Add margin to create space between title and line
                  }}
                />
              </div>
              {/* Body Text */}
              <div style={{ flex: '1 0 auto', padding: '0' }}>
                <p style={{ margin: '0' }}><b>Creatimus</b> is excited to announce the release of Hazbot V1.1.2 in testing mode. Currently, Hazbot is available exclusively to authorized testers and affiliates. Stay tuned for future updates!</p>
              </div>  
              {/* Date Section */}
              <div style={{ textAlign: 'right', marginTop: 'auto', padding: '10px 10px 10px 0' }}>
                <small style={{ fontWeight: 'bold' }}>September 27th, 2024</small>
              </div>
            </div>
          </Col>
          {/* Column 2 */}
          <Col lg={4} md={6} className="mb-4">
            <div
              className="d-flex flex-column position-relative text-start"
              style={{
                border: '2px solid #60d5ff',
                borderRadius: '15px',
                backgroundColor: '#0f111a',
                color: '#ffffff',
                height: '100%',
                padding: '10px',
                boxSizing: 'border-box'
              }}
            >
              {/* Title Section */}
              <div style={{ margin: '0 0 10px', flex: '0 1 auto' }}>
                <h5 style={{ margin: '0', fontWeight: 'bold', color: bluishColor }}>
                The Role of AI in Hazmat Response
                </h5>
                <div
                  style={{
                    height: '2px',
                    backgroundColor: '#60d5ff',
                    marginTop: '10px', // Add margin to create space between title and line
                  }}
                />
              </div>

              {/* Body Text */}
              <div style={{ flex: '1 0 auto', padding: '0' }}>
  <p style={{ margin: '0', display: 'inline-block' }}>
    <small>by </small>
    <small style={{ fontWeight: 'bold' }}>Zac Amos-</small>
    <br />
    Artificial intelligence (AI) excels at analyzing data accurately and efficiently. 
    While those advantages apply to virtually any industry, high-risk working environments 
    may benefit from them more than most. Hazmat response is one of the more niche but 
    promising use cases for this technology.
    {/* "Read More" Button Inline with Text */}
    <Button
      variant="outline-primary"
      onClick={() => window.open('https://hackernoon.com/the-role-of-ai-in-hazmat-response', '_blank')}
      endIcon={<FontAwesomeIcon icon={faAngleRight} transform="down-1" />}
      style={{ 
        fontSize: '12px', // Smaller font size
        padding: '2px 6px', // Adjust padding to reduce size
        marginLeft: '8px' // Space between text and button
      }}
    >
      Read More
    </Button>
  </p>
</div>
              {/* Date Section */}
              <div style={{ textAlign: 'right', marginTop: 'auto', padding: '10px 10px 10px 0' }}>
                <small style={{ fontWeight: 'bold' }}>July 6th, 2024</small>
              </div>
            </div>
          </Col>
         {/* Column 3 */}
          <Col lg={4} md={6} className="mb-4">
            <div
              className="d-flex flex-column position-relative text-start"
              style={{
                border: '2px solid #60d5ff',
                borderRadius: '15px',
                backgroundColor: '#0f111a',
                color: '#ffffff',
                height: '100%',
                padding: '10px',
                boxSizing: 'border-box'
              }}
            >
              {/* Title Section */}
              <div style={{ margin: '0 0 10px', flex: '0 1 auto' }}>
                <h5 style={{ margin: '0', fontWeight: 'bold', color: bluishColor }}>
                Toxic chemicals can be detected with new AI method
                </h5>
                <div
                  style={{
                    height: '2px',
                    backgroundColor: '#60d5ff',
                    marginTop: '10px', // Add margin to create space between title and line
                  }}
                />
              </div>
              {/* Body Text */}
              <div style={{ flex: '1 0 auto', padding: '0' }}>
  <p style={{ margin: '0', display: 'inline-block' }}>
    <small>by </small>
    <small style={{ fontWeight: 'bold' }}>Envirotech Online-</small>
    <br />
    Swedish researchers from Chalmers University of Technology and the University of Gothenburg have devised a novel AI method adept at detecting toxic chemicals based solely on their molecular structures. This innovation not only promises to enhance our grasp on the myriad of chemicals pervading society but also offers a pathway to curtail animal testing.
    {/* "Read More" Button Inline with Text */}
    <Button
      variant="outline-primary"
      onClick={() => window.open('https://www.envirotech-online.com/news/pfas-in-water/154/chalmers-university-of-technology/toxic-chemicals-can-be-detected-with-new-ai-method/62669', '_blank')}
      endIcon={<FontAwesomeIcon icon={faAngleRight} transform="down-1" />}
      style={{ 
        fontSize: '12px', // Smaller font size
        padding: '2px 6px', // Adjust padding to reduce size
        marginLeft: '8px' // Space between text and button
      }}
    >
      Read More
    </Button>
  </p>
</div>
              {/* Date Section */}
              <div style={{ textAlign: 'right', marginTop: 'auto', padding: '10px 10px 10px 0' }}>
                <small style={{ fontWeight: 'bold' }}>May 17th, 2024</small>
              </div>
            </div>
          </Col>
            </Row>
      </Container>
    </section>
  );
};
export default TeamSection;
