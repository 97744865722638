import axios from 'axios';
import { apiUrl } from './utils';
import { IMailUpdateVerification, IPasswordUpdate, IUser } from 'data/models/user/IUser';

const getHatateToken = () => {
    const token = localStorage.getItem('hatateToken');
    return token ? `Bearer ${token}` : '';
  };
export const UserService = {
  login: async (request: string) => {
    const params = JSON.stringify(request);
    const headers = { 'Content-Type': 'application/json' };
    try {
      const response = await axios.post(`${apiUrl}authentication/login`, params, { headers });
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : error.message;
    }
  },

  getUser: async (id?: string) : Promise<IUser> => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: getHatateToken(),
    };
    const url = id ? `${apiUrl}/user/${id}` : `${apiUrl}user/profile/me`;
    try {
      const response = await axios.get(url, { headers });

      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : error.message;
    }
  },

  getAvailableNeuroSkills: async () => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: getHatateToken(),
    };
    const url = `${apiUrl}/bot/neuroskill/list`;
    try {
      const response = await axios.get(url, { headers });
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : error.message;
    }
  },

  getPromptResponse: async (
    neuroSkill: string,
    neuroModule: string,
    messages: { role: 'user' | 'assistant'; content: string }[]
  ): Promise<string> => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: getHatateToken(),
    };
  
    const url = apiUrl + "bot/prompt";
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          skill: neuroSkill,
          module: neuroModule,
          prompt: messages
        })
      });
  
      if (!response.ok) {
        throw new Error(`${response.status}`);
      }
  
      const data = await response.json();
      if (typeof data.reply !== 'string') {
        throw new Error('Unexpected response format: reply is not a string');
      }
      return data.reply;
    } catch (error) {
      console.error("Error in getPromptResponse:", error);
      throw error;
    }
  },

  mailVerification: async () => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: getHatateToken(),
    };
    try {
      const response = await axios.get(`${apiUrl}user/update-mail-verification`, { headers });
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : error.message;
    }
  },

  mailUpdate: async (update: IMailUpdateVerification) => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: getHatateToken(),
    };
    try {
      const response = await axios.post(`${apiUrl}user/update-mail-verification`, update, { headers });
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : error.message;
    }
  },

  updateUser: async (user: IUser) => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: getHatateToken(),
    };
    try {
      const response = await axios.put(`${apiUrl}user`, user, { headers });
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : error.message;
    }
  },

  passwordUpdate: async (request: IPasswordUpdate) => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: getHatateToken(),
    };
    try {
      const response = await axios.put(`${apiUrl}user/update-password`, request, { headers });
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : error.message;
    }
  },

  userListAutocompleteObservable: async (own = false, search = '') => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: getHatateToken(),
    };
    const route = own ? '/true' : '';
    const searchQuery = search ? `?search=${search}` : '';
    try {
      const response = await axios.get(`${apiUrl}user/actions/list-all${route}${searchQuery}`, { headers });
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : error.message;
    }
  },

  userIp: async () => {
    try {
      const response = await axios.get('https://api.ipify.org?format=json');
      return response.data;
    } catch (error: any) {
      throw error.response ? error.response.data : error.message;
    }
  }
};