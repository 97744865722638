import PhoenixDocCard from 'components/base/PhoenixDocCard';
import DocPageHeader from 'components/docs/DocPageHeader';
import DocPagesLayout from 'layouts/DocPagesLayout';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

const GettingStarted = () => {
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <DocPageHeader className="mb-4" title="Getting Started">
        <p className="mb-0">
          Welcome to the HAZBOT Documentation. Below you'll find detailed sections outlining the capabilities of the platform.
        </p>
      </DocPageHeader>

      <DocPagesLayout>
        {/* Section 1: Calculation */}
        <PhoenixDocCard className="mb-4">
          <PhoenixDocCard.Header title="I. Calculations" noPreview />
          <PhoenixDocCard.Body>
            <p>
              HAZBOT leverages advanced calculation libraries for various technical analyses.
            </p>
            <p><h6>TECHNICAL ANALYSIS - ADVANCED CALCULATION LIBRARIES AVAILABLE FOR HAZBOT</h6></p><br></br>
            <h6>CHEMPY (CHEMISTRY PYTHON)</h6>
            <p><strong>Key Capabilities:</strong></p>
            <ul>
              <li>Stoichiometry: Automatic balancing of chemical equations.</li>
              <li>Thermodynamics: Gibbs calculations of enthalpy, entropy, and free energy.</li>
              <li>Chemical kinetics: Modeling of reactions and calculation of velocities.</li>
              <li>Chemical equilibrium: Determination of equilibrium constants.</li>
            </ul>
            <p><strong>Advanced Applications:</strong></p>
            <ul>
              <li>Simulation of reactions in the gas phase and solution.</li>
              <li>Chemical speciation analysis.</li>
              <li>pH and solubility calculations.</li>
            </ul>

            <h6>PINT (PHYSICAL QUANTITIES)</h6>
            <p><strong>Key Capabilities:</strong></p>
            <ul>
              <li>Unit Manipulation: Allows calculations to be performed with physical units.</li>
              <li>Unit conversion: Facilitates the transformation between measurement systems.</li>
              <li>Dimensional verification: Ensures consistency in physical calculations.</li>
            </ul>
            <p><strong>Advanced Applications:</strong></p>
            <ul>
              <li>Propagation of uncertainties in physical calculations.</li>
              <li>Definition of customized unit systems.</li>
              <li>NumPy integration for vector calculations with units.</li>
            </ul>

            <h6>SCIPY (SCIENTIFIC PYTHON)</h6>
            <p><strong>Key Capabilities:</strong></p>
            <ul>
              <li>Optimization: Implements advanced algorithms such as BFGS, Nelder-Mead, and Powell.</li>
              <li>Numerical integration: Offers adaptive methods such as quad and romberg.</li>
              <li>Linear Algebra: Provides optimized LAPACK routines.</li>
              <li>Fourier Transforms: Implements efficient FFT and its variants.</li>
              <li>Signal processing: Includes FIR/IIR filters and window design.</li>
            </ul>
            <p><strong>Advanced Applications:</strong></p>
            <ul>
              <li>Resolution of EDOs/EDPs with 'scipy.integrate.ode' and 'scipy.integrate.solve_ivp'.</li>
              <li>Multi-dimensional interpolation with 'scipy.interpolate'.</li>
              <li>Statistics and hypothesis testing with 'scipy.stats'.</li>
            </ul>

            <h6>SYMPY (SYMBOLIC PYTHON)</h6>
            <p><strong>Key Capabilities:</strong></p>
            <ul>
              <li>Symbolic manipulation: Allows complex algebraic operations.</li>
              <li>Differential and Integral Symbolic Calculus: Derivation, Integration and Limits.</li>
              <li>Solving equations: Symbolic solver for systems of equations.</li>
              <li>Series and expansions: Taylor, Laurent, and other series.</li>
            </ul>
            <p><strong>Advanced Applications:</strong></p>
            <ul>
              <li>Automatic simplification of expressions with 'sympy.simplify'.</li>
              <li>Tensor calculus with 'sympy.tensor'.</li>
              <li>Group theory with 'sympy.combinatorics'.</li>
            </ul>

            <h6>NUMPY (NUMERICAL PYTHON)</h6>
            <p><strong>Key Capabilities:</strong></p>
            <ul>
              <li>Vectorized operations: Enables efficient calculations on large data sets.</li>
              <li>Linear Algebra: Includes optimized matrix operations.</li>
              <li>Random Number Generation: Offers multiple probabilistic distributions.</li>
              <li>Fourier Transforms: Efficient FFT Implementation.</li>
            </ul>
            <p><strong>Advanced Applications:</strong></p>
            <ul>
              <li>Broadcast operations for multidimensional calculations.</li>
              <li>Boolean masks for filtering and manipulation of complex data.</li>
              <li>Universal functions (ufuncs) for highly optimized element-by-element operations.</li><br></br>
            </ul>
            <br></br>
           <h6>PRACTICAL APPLICATIONS IN CHEMICAL EMERGENCIES AND INDUSTRIAL SERVICES</h6><br></br><br></br>
            <h6>TOXIC GAS DISPERSION MODELING (SCIPY + NUMPY)</h6>
            <strong>Scenarios:</strong>
            <ul>
              <li>Toxic gas leak in an industrial plant. (i.e. Chlorine)</li>
              <li>Leakage of a flammable and toxic gas from a railroad tank car. (i.e. 1,3-Butadiene)</li>
              <li>Leakage of a toxic cryogenic product from a tank truck on a highway. (i.e. Fluorine), etc.</li>
            </ul>
            <p><strong>Application:</strong></p>
            <ul>
              <li>Using SciPy and NumPy, Hazbot can implement a Gaussian scattering model to predict the concentration of the toxic gas at different geographical points over time.</li>
            </ul>
            <br></br><br></br>
            <h6>HAZARDOUS CHEMICAL REACTION ANALYSIS (CHEMPY + SYMPY)</h6>
            <p><strong>Scenarios:</strong></p>
            <ul>
              <li>Spill and mixture of (i.e.) sulfuric acid with nitric acid inside a chemical storage warehouse, with violent reaction and large gas generation.</li>
              <li>Violent reaction during neutralization of a spill of (i.e.) hydrochloric acid with alkaline pH-14 product.</li>
              <li>Fire in a chemical plant affecting a (i.e.) hydrofluoric acid storage tank.</li>
            </ul>
            <p><strong>Application:</strong></p>
            <ul>
              <li>Hazbot can use ChemPy and SymPy to model potential exothermic chemical reactions in multiple scenarios, analyze process thermodynamics.</li>
              <li>Hazbot can provide valuable information to the personnel in charge of developing the scene analysis, risk assessment, and control measures of the event.</li>
            </ul>
            <br></br><br></br>
            <h6>PRESSURE CALCULATIONS IN PIPELINES FOR CHEMICAL TRANSPORT (NUMPY + PINT)</h6>
            <p><strong>Scenario:</strong></p>
            <ul>
              <li>Pressure monitoring in a complex piping system in a refinery.</li>
            </ul>
            <p><strong>Application:</strong></p>
            <ul>
              <li>Hazbot can use NumPy for vectorized calculations and Pint to handle units consistently.</li>
            </ul>
            <p><strong>Calculating Pressure Loss Using the Hazen-Williams Equation:</strong></p>
            <ul>
              <li>D: Pipe Diameter</li>
              <li>L: Pipe Length</li>
              <li>Q: Flow rate</li>
              <li>C: Hazen-Williams coefficient</li>
              <li>return 10.67 * L * (Q/C)**1.852 / D**4.87</li>
            </ul><br></br><br></br>
            <p><strong>Data from different pipe sections:</strong></p>
            <pre>
              <code>
              diameters = np.array([0.1, 0.15, 0.2]) * ureg.meter
              lengths = np.array([100, 150, 200]) * ureg.meter 
              flows = np.array([0.05, 0.07, 0.1]) * ureg.meter**3 / ureg.second
              C_values = np.array([100, 120, 140]) # Dimensionless 
              </code>
            </pre><br></br><br></br>
            <p><strong>Vectorized Calculation:</strong></p>
            <pre>
              <code>
              pressure_losses = hazen_williams(diameters, lengths, flows, C_values) * ureg.pascal
              print("Pressure Losses:")
              for i, loss in enumerate(pressure_losses):
                  {'print(f\"Section {i+1}: {loss.to(ureg.bar):.2f}\")'}
              </code>
            </pre><br></br><br></br>
            <p><strong>Total System Pressure:</strong></p>
            <pre>
              <code>
              total_loss = np.sum(pressure_losses)
              {'print(f\"Total pressure loss: {total_loss.to(ureg.bar):.2f}\")'}
              </code>
            </pre>
            <p>
              These types of calculations allow industrial operators to monitor and maintain safe pressures in complex piping systems, preventing potentially catastrophic failures.
            </p>
          </PhoenixDocCard.Body>
        </PhoenixDocCard>

        {/* Section 2: Regulations, Standards and Procedures */}
        <PhoenixDocCard className="mb-4">
          <PhoenixDocCard.Header title="II. Regulations, Standards and Procedures" noPreview />
          <PhoenixDocCard.Body>
            <p>
              Hazbot has multiple resources available that cover relevant compliance aspects of regulations, standards, and procedures.
            </p>
            <h6>1. General Knowledge Base:</h6>
            <p>
              The Hazbot knowledge base includes general information about international regulations and standards.
            </p>
            <h6>2. Specificity in Chemical and Industrial Emergencies:</h6>
            <p>
              Hazbot knowledge about key regulations such as US-OSHA, US-EPA, US-DOT, REACH (European Union), etc., and standards such as ISO, NFPA, ASTM, etc.
            </p>
            <h6>3. Data Update:</h6>
            <p>
              Hazbot has a specialized Technical Library which is permanently improved and updated, access to technical tools such as PubChem and many other libraries.
            </p>
            <h6>4. Compliance and Audits:</h6>
            <p>
              Hazbot has general knowledge of compliance practices and audits, especially in those aspects related to chemical emergencies, chemical transportation, chemical storage, training, and emergency response.
            </p>
            <h6>5. Contextual Interpretation Capacity:</h6>
            <p>
              Hazbot can interpret and apply regulatory information to specific contexts based on its training.
            </p>
            <h6>6. Predictive Compliance Analytics:</h6>
            <p>
              Hazbot is being trained with machine learning systems to predict regulatory trends or compliance risks, gradually developed and improved.
            </p>
            <h6>7. Standard Operating Procedures (SOPs):</h6>
            <p>
              Hazbot has training and technical resources that can assist in the development and implementation of standard operating procedures related to:
            </p>
            <ul>
              <li>Initial Scenario Assessment</li>
              <li>Containment and Control</li>
              <li>Communication and Coordination</li>
              <li>Monitoring and Follow-up</li>
              <li>Documentation and Reporting</li>
            </ul>
          </PhoenixDocCard.Body>
        </PhoenixDocCard>

        {/* Section 3: Multilingual Resources */}
        <PhoenixDocCard className="mb-4">
          <PhoenixDocCard.Header title="III. Multilingual Resources" noPreview />
          <PhoenixDocCard.Body>
            <p>
              Hazbot has the ability to interact and provide information in multiple languages. Currently, its multilanguage system is oriented to English (default), Spanish, French, Italian, Portuguese, German, Mandarin Chinese, Hindi, Japanese, Urdu, Arabic, Greek, Hebrew, Croatian, Ukrainian, and Hungarian.
            </p>
            <p>
              Hazbot's multilingual capability allows communication in these languages and includes texts in those languages. Hazbot is constantly integrating new languages into its multi-language library.
            </p>
          </PhoenixDocCard.Body>
        </PhoenixDocCard>

        {/* Section 4: Physical-Chemical Aspect */}
        <PhoenixDocCard className="mb-4">
          <PhoenixDocCard.Header title="IV. Physical-Chemical Aspect" noPreview />
          <PhoenixDocCard.Body>
            <p>
              Hazbot has training and resources to actively collaborate on multiple essential aspects of chemical emergencies and complex industrial services.
            </p>
            <h6>1. Advanced Gas Dispersion Modeling:</h6>
            <p>
              Implement heavy and light gas dispersion models, integrate real-time weather data for accurate calculations, and visualize impact zones on maps.
            </p>
            <h6>2. Chemical Reaction Simulation:</h6>
            <p>
              Development of predictive simulation engines for products and heat of reaction, along with chemical kinetics databases for common reactions.
            </p>
            <h6>3. Advanced Chemical Compatibility Analysis:</h6>
            <p>
              Create a dynamic compatibility matrix based on redox and reactivity properties, and set warnings about potentially dangerous mixtures.
            </p>
            <h6>4. Thermodynamic Properties Calculator:</h6>
            <p>
              Implement advanced state equations for real gases and calculate properties under supercritical conditions.
            </p>
            <h6>5. Environmental Degradation Prediction System:</h6>
            <p>
              Model degradation rates under different environmental conditions and estimate the persistence of contaminants in soil, water, and air.
            </p>
            <h6>6. Polymerization Risk Analyzer:</h6>
            <p>
              Assess risks of spontaneous polymerization and recommend appropriate inhibitors and storage conditions.
            </p>
            <h6>7. Cryogenics Module:</h6>
            <p>
              Calculate evaporation rates of cryogenic liquids and assess risks of embrittlement of materials at low temperatures.
            </p>
            <h6>8. Dilution and Neutralization Simulator:</h6>
            <p>
              Calculate optimal ratios for spill neutralization and model the dispersion of pollutants in water bodies.
            </p>
            <h6>9. Critical Point Analyzer:</h6>
            <p>
              Generate interactive phase diagrams and calculate optimal conditions for separation processes.
            </p>
            <h6>10. Phase Change Alert System:</h6>
            <p>
              Predict phase changes based on environmental conditions and warn of risks of rapid expansion or contraction.
            </p>
            <h6>11. Corrosion Assessment Module:</h6>
            <p>
              Predict corrosion rates based on redox properties and environmental conditions, and suggest corrosion-resistant material alternatives.
            </p>
            <h6>12. Gas Expansion Calculator:</h6>
            <p>
              Estimate gas volumes generated in liquefied gas leaks.
            </p>
          </PhoenixDocCard.Body>
        </PhoenixDocCard>

        {/* Section 5: Marine and On Board Ships Emergencies */}
        <PhoenixDocCard className="mb-4">
          <PhoenixDocCard.Header title="V. Marine and On Board Ships Emergencies" noPreview />
          <PhoenixDocCard.Body>
            <p>
              Hazbot can provide support in various aspects related to the spillage of chemicals into the sea and emergencies on board transport vessels.
            </p>
            <h6>1. International Regulatory Framework:</h6>
            <p>
              Main Agreements include MARPOL, SOLAS, IMDG Code, and OPRC Convention.
            </p>
            <h6>2. Types of Marine Emergencies:</h6>
            <ul>
              <li><strong>Cargo-Related:</strong> Oil spills, chemical leaks, cargo fire, uncontrolled chemical reactions.</li>
              <li><strong>Related to the Ship:</strong> Collisions, strandings, fires on board, floods, loss of stability.</li>
              <li><strong>Environmental Emergencies:</strong> Ballast water pollution, cargo loss pollution, impact on marine ecosystems.</li>
            </ul>
            <h6>3. Types of Chemical Transport Vessels:</h6>
            <p>
              Types include chemical tankers, vessels for liquefied gas, and combined cargo ships.
            </p>
            <h6>4. Resources for Onboard Emergencies:</h6>
            <ul>
              <li>Containment Equipment: Containment barriers, skimmers, chemical absorbents.</li>
              <li>Fire Suppression Systems: CO2 systems, fire foam, water spray systems.</li>
              <li>Protective Equipment (PPE and ERP): Protective suits, respiratory protection.</li>
              <li>Personal Detection and Monitoring Equipment: Fuel gas indicators, oxygen meters, pH meters.</li>
            </ul>
            <h6>5. Spill Dispersion Calculation:</h6>
            <p>
              Computational models such as GNOME and ADIOS help predict spill trajectories and impacts.
            </p>
          </PhoenixDocCard.Body>
        </PhoenixDocCard>
      </DocPagesLayout>
    </div>
  );
};

export default GettingStarted;

