import bgbiz from 'assets/img/bg/bg-23.png';
import { Col, Row, Container } from 'react-bootstrap';
import Button from 'components/base/Button';

const Enterprise = () => {
  return (
<section id="enterprise" className="alternate-landing-team" style={{ position: 'relative', overflow: 'hidden', padding: '60px 0', overflowX: 'hidden' }}>

    {/* Background Overlay */}
      <div
        className="position-absolute w-100 h-100 start-0 end-0 top-0"
        style={{
          backgroundColor: 'rgba(15, 17, 26, 0.5)', // Semi-transparent overlay
          zIndex: 1
        }}
      />
      {/* Background Image */}
      <div
        className="bg-holder d-none d-xl-block"
        style={{
          backgroundImage: `url(${bgbiz})`,
          backgroundSize: 'contain', // Change to 'contain' to fit within the section
          backgroundPosition: 'center',
          zIndex: 0,
          height: '100%',
          width: '100%',
        }}
      />

      <Container className="d-flex flex-column align-items-center mt-5" style={{ position: 'relative', zIndex: 2 }}>
      <div className="text-center mb-4">
          <h1 className="text-gradient-info mt-8 mb-4">Enterprise Solutions</h1>
        </div>
        <Row className="justify-content-center">
          <Col
            lg={10}
            className="p-5 text-center"
            style={{
              border: '2px solid #60d5ff',
              borderRadius: '15px',
              backgroundColor: '#0f111a'
            }}
          >
            <h3 className="mb-5" style={{ color: '#60d5ff' }}>
              Unlock Tailored Solutions for Your Business
            </h3>
            <p className="text-white mb-4">
              We invite business customers to reach out to us for a personalized consultation. Our goal is to understand your unique requirements, evaluate your needs, and collaborate closely to craft solutions that align perfectly with your objectives. Let us partner with you to help your business thrive and grow with technology tailored specifically to you. <b>Integrate Hazbot into your business today!</b>
            </p>
          </Col>
        </Row>

        {/* Action Button */}
        <Row className="justify-content-center mt-4">
          <Button
            href="#support"
            variant="primary"
            size="lg"
            className="rounded-pill"
            style={{
              transition: 'background-color 0.3s ease',
            }}
            onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#004dff'}
            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = ''}
          >
            Let's Connect!
          </Button>
        </Row>
      </Container>
    </section>
  );
};

export default Enterprise;
