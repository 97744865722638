import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import { features } from 'data/landing/alternate-landing-data';
import FeatureSection from './FeatureSection';
import { Col, Row, Container } from 'react-bootstrap';

const Features = () => {
  return (
    <section id="features" className="pt-8 pb-0">
      <Container className="container-small px-lg-7 px-xxl-3">
        <div className="text-center mb-4">
          <h1 className="text-center text-gradient-info mt-0 mb-4">Features</h1>
        </div>
        <h2 className="text-center mb-3 lh-base">
          Complete suite of <br className="d-sm-none" />
          Skills and Abilities.
        </h2>
        <p className="text-center mb-0">
          Comprehensive set of modular NeuroSkills designed to enhance Hazbot's capabilities.
        </p>
        <div className="text-center mt-5 mb-5">
          <Button
            variant="outline-primary"
            endIcon={<FontAwesomeIcon icon={faAngleRight} transform="down-1" />}
          >
            See more
          </Button>
        </div>

        {/* First Row: Display Features 1 and 2 */}
        <Row className="g-4">
          {features.slice(0, 2).map((feature, index) => (
            <Col key={feature.id} md={6}>
              <FeatureSection feature={feature} isLast={index === features.length - 1} />
            </Col>
          ))}
        </Row>

        {/* Second Row: Display Features 3 and 4 */}
        <Row className="g-4 mt-5">
          {features.slice(2, 4).map((feature, index) => (
            <Col key={feature.id} md={6}>
              <FeatureSection feature={feature} isLast={index === features.length - 1} />
            </Col>
          ))}
        </Row>

        {/* Third Row: Display Feature 5 (Centered) */}
        <Row className="g-4 justify-content-center mt-5">
          <Col md={6} className="d-flex justify-content-center">
            <FeatureSection feature={features[4]} isLast={true} />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Features;
