//import React from 'react';
import logo from 'assets/img/icons/logo.png';
import classNames from 'classnames';

interface LogoProps {
  width?: number;
  text?: boolean;
  textClass?: string;
  className?: string;
}

const Logo = ({ width = 125, text = true, textClass, className }: LogoProps) => {
  return (
    <div className={classNames(className, 'd-flex align-items-center')}>
      <img src={logo} alt="hazbot" width={width}/>
      {text && (
        <p className={classNames(textClass, 'logo-text ms-2')}>&nbsp;</p>
      )}
    </div>
  );
};

export default Logo;
