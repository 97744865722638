import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope, faBuilding, faLock } from '@fortawesome/free-solid-svg-icons';
import Button from 'components/base/Button';
import { Col, Form, Row, Modal } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { apiUrl, getUserIp } from "helpers/utils";
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const formIconStyles = `
.form-icon-container {
position: relative;
}
.form-icon-input {
padding-left: 35px; /* Adjust this value as needed */
}
.form-icon {
position: absolute;
left: 10px;
top: 50%;
transform: translateY(-1%);
color: #adb5bd;
}
`;

const SignUpForm = ({ layout }: { layout: 'simple' | 'card' | 'split' }) => {
    const [hovered, setHovered] = useState(false); // State to track hover status
    
const navigate = useNavigate();
const [formData, setFormData] = useState({
name: '',
lastName: '',
company: '',
email: '',
password: '',
confirmPassword: '',
});
const [termsChecked, setTermsChecked] = useState(false);
const [showSuccessModal, setShowSuccessModal] = useState(false);
const [showErrorModal, setShowErrorModal] = useState(false);
const [modalMessage, setModalMessage] = useState('');
const [isLoading, setIsLoading] = useState(false);
const [showTermsModal, setShowTermsModal] = useState(false);
const [showPrivacyModal, setShowPrivacyModal] = useState(false);
const [termsAccepted, setTermsAccepted] = useState(false);
const [privacyAccepted, setPrivacyAccepted] = useState(false);

const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
const { id, value } = e.target;
setFormData({ ...formData, [id]: value });
};

const handleTermsAccepted = () => {
setTermsAccepted(true);
checkBothAccepted();
};

const handlePrivacyAccepted = () => {
setPrivacyAccepted(true);
checkBothAccepted();
};

const checkBothAccepted = () => {
if (termsAccepted && privacyAccepted) {
setTermsChecked(true);
}
};

const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
setTermsChecked(e.target.checked);
};

const handleSignUp = async (e: React.FormEvent<HTMLFormElement>) => {
e.preventDefault();

if (isLoading) return;

setIsLoading(true);

if (formData.password !== formData.confirmPassword) {
setModalMessage('Passwords do not match');
setShowErrorModal(true);
setIsLoading(false);
return;
}

if (!termsChecked) {
setModalMessage('You must accept the terms and conditions');
setShowErrorModal(true);
setIsLoading(false);
return;
}

let address;
try {
address = await getUserIp();
if (!address) {
throw new Error('Could not get your IP address for registration.');
}
} catch (error) {
setModalMessage('Could not get your IP address for registration.');
setShowErrorModal(true);
setIsLoading(false);
return;
}

const requestBody = {
name: formData.name,
lastName: formData.lastName,
company: formData.company,
email: formData.email,
password: formData.password,
address: address
};

try {
const response = await axios.post(
`${apiUrl}authentication/register`,
requestBody,
{
headers: {
'Content-Type': 'application/json'
},
withCredentials: true
}
);

if (response.data.token) {
console.log(response.data.token);
localStorage.setItem("hatateToken", response.data.token);
setModalMessage('Registration successful.');
setShowSuccessModal(true);
}
} catch (error: any) {
console.error(error);
if (!error.response) {
setModalMessage('No response received from Hatate backend');
} else {
setModalMessage(error.response.data.message);
}
setShowErrorModal(true);
} finally {
setIsLoading(false);
}
};

const handleCloseModal = () => {
setShowSuccessModal(false);
setShowErrorModal(false);
if (showSuccessModal) {
navigate('/');
}
};

return (
<>
<style>{formIconStyles}</style>
<div className="text-center mb-1">
<h3 className="text-body-highlight mb-2">Sign Up</h3>
<p className="text-body-tertiary">Create your account today</p>
</div>
<Form onSubmit={handleSignUp}>
<Form.Group className="mb-3 text-start">
<Form.Label htmlFor="name">Name</Form.Label>
<div className="form-icon-container">
<Form.Control
id="name"
type="text"
placeholder="Name"
value={formData.name}
onChange={handleInputChange}
required
className="form-icon-input"
/>
<FontAwesomeIcon icon={faUser as IconProp} className="form-icon" />
</div>
</Form.Group>
<Form.Group className="mb-3 text-start">
<Form.Label htmlFor="lastName">Last Name</Form.Label>
<div className="form-icon-container">
<Form.Control
id="lastName"
type="text"
placeholder="Last Name"
value={formData.lastName}
onChange={handleInputChange}
required
className="form-icon-input"
/>
<FontAwesomeIcon icon={faUser as IconProp} className="form-icon" />
</div>
</Form.Group>
<Form.Group className="mb-3 text-start">
<Form.Label htmlFor="company">Company</Form.Label>
<div className="form-icon-container">
<Form.Control
id="company"
type="text"
placeholder="Company"
value={formData.company}
onChange={handleInputChange}
required
className="form-icon-input"
/>
<FontAwesomeIcon icon={faBuilding as IconProp} className="form-icon" />
</div>
</Form.Group>
<Form.Group className="mb-3 text-start">
<Form.Label htmlFor="email">Email address</Form.Label>
<div className="form-icon-container">
<Form.Control
id="email"
type="email"
placeholder="name@example.com"
value={formData.email}
onChange={handleInputChange}
required
className="form-icon-input"
/>
<FontAwesomeIcon icon={faEnvelope as IconProp} className="form-icon" />
</div>
</Form.Group>
<Row className="g-3 mb-3">
<Col sm={layout === 'card' ? 12 : 6} lg={6}>
<Form.Group>
<Form.Label htmlFor="password">Password</Form.Label>
<div className="form-icon-container">
<Form.Control
id="password"
type="password"
placeholder="Password"
value={formData.password}
onChange={handleInputChange}
required
className="form-icon-input"
/>
<FontAwesomeIcon icon={faLock as IconProp} className="form-icon" />
</div>
</Form.Group>
</Col>
<Col sm={layout === 'card' ? 12 : 6} lg={6}>
<Form.Group>
<Form.Label htmlFor="confirmPassword">
Confirm Password
</Form.Label>
<div className="form-icon-container">
<Form.Control
id="confirmPassword"
type="password"
placeholder="Confirm Password"
value={formData.confirmPassword}
onChange={handleInputChange}
required
className="form-icon-input"
/>
<FontAwesomeIcon icon={faLock as IconProp} className="form-icon" />
</div>
</Form.Group>
</Col>
</Row><div className="mb-3" style={{ textAlign: "center" }}>
Please read and accept the <b>Terms of Service</b> and <b>Privacy Policy</b> below to continue.
</div>
<div style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',}}
><Form.Check type="checkbox" className="mb-3">
<Form.Check.Input
type="checkbox"
name="termsService"
id="termsService"
checked={termsChecked}
onChange={handleCheckboxChange}
disabled={!termsAccepted || !privacyAccepted} // Disable checkbox only
style={{
opacity: !termsAccepted || !privacyAccepted ? 0.5 : 1, // Control opacity of the checkbox
cursor: !termsAccepted || !privacyAccepted ? 'not-allowed' : 'pointer' // Change cursor style
}}
/>
<Form.Check.Label
htmlFor="termsService"
className="text-body-tertiary"
style={{
opacity: 1 // Keep label text fully visible
}}
>
I accept the{' '}
<Link to="#"
      className="text-primary"
      style={{
        color: hovered ? '#007bff' : '#5b8ffa', // Use state to determine color
        cursor: 'pointer',
        transition: 'color 0.3s ease',
      }}
      onClick={() => setShowTermsModal(true)}
      onMouseDown={(e) => e.preventDefault()} // Prevent checkbox toggle
      onMouseEnter={() => setHovered(true)} // Use onMouseEnter instead of onMouseOver
      onMouseLeave={() => setHovered(false)} // Use onMouseLeave instead of onMouseOut
    >
      Terms of Service
    </Link>
{' '}
and{' '}
<Link to="#"

  className="text-primary"
  style={{
    color: '#5b8ffa', // Default color
    cursor: 'pointer',
    transition: 'color 0.3s ease',
    
  }}  
  onClick={() => setShowPrivacyModal(true)}
  onMouseDown={(e) => e.preventDefault()} // Prevent checkbox toggle

>
  Privacy Policy.
</Link>

</Form.Check.Label>
</Form.Check>
</div>
<Button
variant="primary"
type="submit"
disabled={isLoading || !termsChecked}
style={{ width: '100%' }} // Make button full width
>
{isLoading ? 'Loading...' : 'Sign Up'}
</Button>
</Form>

{/* Success Modal */}
<Modal show={showSuccessModal} onHide={handleCloseModal}>
<Modal.Header closeButton>
<Modal.Title>Success</Modal.Title>
</Modal.Header>
<Modal.Body>{modalMessage}</Modal.Body>
<Modal.Footer>
<Button variant="secondary" onClick={handleCloseModal}>
Close
</Button>
</Modal.Footer>
</Modal>

{/* Error Modal */}
<Modal show={showErrorModal} onHide={handleCloseModal}>
<Modal.Header closeButton>
<Modal.Title>Error</Modal.Title>
</Modal.Header>
<Modal.Body>{modalMessage}</Modal.Body>
<Modal.Footer>
<Button variant="secondary" onClick={handleCloseModal}>
Close
</Button>
</Modal.Footer>
</Modal>

{/* Terms Modal */}
<Modal show={showTermsModal} onHide={() => setShowTermsModal(false)} onExited={handleTermsAccepted}>
<Modal.Header closeButton>
<Modal.Title>Terms of Service</Modal.Title>
</Modal.Header>
<Modal.Body>
<h3>Effective Date: November 1st, 2024</h3>
<h3>Last Updated: October 24th, 2024</h3>
<br></br>
<p>
Welcome to Hazbot. Please read these Terms and Conditions (“Terms”) carefully before using our website or services. By accessing or using Hazbot’s website or software, you agree to be bound by these Terms. If you do not agree, please discontinue use immediately.
</p>

<h4>1. About Hazbot and Ownership</h4>
<p>
Hazbot and its associated website are products of Creatimus LLC, headquartered in Houston, Texas. Hazbot’s technology infrastructure and AI services are developed in partnership with Linuxcorp USA. Linuxcorp Latam serves as the exclusive representative of Creatimus for Hazbot in Chile and Latin America.
</p>

<h4>2. Eligibility and User Responsibility</h4>
<ol>
<li>Users must be at least 18 years old or have the consent of a legal guardian.</li>
<li>Users agree to use Hazbot only for lawful purposes related to chemical risk analysis, hazard management, or other intended functionalities.</li>
<li>Users are responsible for maintaining the confidentiality of their account credentials and for all activities that occur under their account.</li>
</ol>

<h4>3. Advisory Nature of Hazbot’s Services</h4>
<p>
Hazbot is an advisory tool only and is not intended to replace the advice or judgment of certified professionals. All final decisions, actions, or inactions taken by users are their sole responsibility.
</p>
<ul>
<li>Users must consult certified experts and professionals before using Hazbot’s recommendations in real emergencies or critical situations.</li>
<li>Hazbot provides guidance based on its algorithms and data but does not guarantee accuracy, completeness, or suitability for any specific scenario.</li>
</ul>

<h4>4. Intellectual Property Rights</h4>
<ol>
<li>All content, code, data, and materials on Hazbot’s platform, including logos, trademarks, software, and documentation, are the property of Creatimus LLC and its partners.</li>
<li>Users are granted a limited, non-exclusive, non-transferable license to access and use Hazbot solely for personal or business purposes. Unauthorized copying, modification, or distribution is strictly prohibited.</li>
</ol>

<h4>5. Acceptable Use Policy</h4>
<ol>
<li>Users agree not to:
<ul>
<li>Violate any applicable laws or regulations.</li>
<li>Use Hazbot’s services for unauthorized purposes or malicious activities.</li>
<li>Interfere with the functionality or security of the website or any connected networks.</li>
</ul>
</li>
<li>Creatimus LLC reserves the right to suspend or terminate accounts that engage in prohibited activities.</li>
</ol>

<h4>6. Disclaimer of Warranties</h4>
<p>
Hazbot’s services are provided "as-is" without warranties of any kind, express or implied. The platform’s recommendations should not be used as a substitute for professional judgment in real-life emergencies.
</p>
<ul>
<li>Users assume all risks related to the application of Hazbot’s advice.</li>
<li>Creatimus LLC and its partners do not guarantee that Hazbot’s information is accurate, up-to-date, or applicable to every situation.</li>
</ul>

<h4>7. Limitation of Liability</h4>
<ol>
<li>Creatimus LLC, Linuxcorp USA, and Linuxcorp Latam are not liable for any damages resulting from decisions, actions, or outcomes based on Hazbot’s advice or data.</li>
<li>Users acknowledge that all responsibility for decisions rests with them, and the use of Hazbot’s recommendations is at their own risk.</li>
<li>Hazbot does not provide emergency response services or crisis management. Users must always consult with appropriate experts or emergency responders before making decisions.</li>
</ol>

<h4>8. Third-Party Services and Links</h4>
<p>
Hazbot’s platform may contain links to third-party websites or services. Creatimus LLC does not endorse or assume responsibility for these external resources and shall not be held liable for any interactions users have with them.
</p>

<h4>9. Privacy Policy</h4>
<p>
All data collected from users is governed by our Privacy Policy, which explains how we collect, store, and protect personal data. By using Hazbot, users agree to the terms of the Privacy Policy.
</p>

<h4>10. User Data and Confidentiality</h4>
<ol>
<li>Hazbot processes and stores information to provide its services. While we strive to secure data, we cannot guarantee the security of transmitted data.</li>
<li>Users must notify us immediately if they suspect any unauthorized access to their account.</li>
</ol>

<h4>11. Modification of Terms</h4>
<p>
Creatimus LLC reserves the right to modify or update these Terms at any time. We will notify users of significant changes through the website or via email. Continued use of the services after changes constitutes acceptance of the revised Terms.
</p>

<h4>12. Termination</h4>
<p>
Creatimus LLC reserves the right to suspend or terminate access to Hazbot services at any time without notice if users violate these Terms or engage in harmful activities.
</p>

<h4>13. Governing Law and Dispute Resolution</h4>
<ol>
<li>These Terms are governed by the laws of the State of Texas, USA, without regard to its conflict of law principles.</li>
<li>Any disputes will be resolved through arbitration in Houston, Texas, unless otherwise required by law.</li>
</ol>

<h4>14. Contact Information</h4>
<p>If you have any questions about these Terms, please contact us at:</p>
<p>
Creatimus LLC<br />
support@creatimus.com
</p>

</Modal.Body>
<Modal.Footer>
<Button variant="primary" onClick={() => setShowTermsModal(false)}>
I Accept
</Button>
</Modal.Footer>
</Modal>

{/* Privacy Modal */}
<Modal show={showPrivacyModal} onHide={() => setShowPrivacyModal(false)} onExited={handlePrivacyAccepted}>
<Modal.Header closeButton>
<Modal.Title>Privacy Policy</Modal.Title>
</Modal.Header>
<Modal.Body>
<h3>Effective Date: November 1st, 2024</h3>
<h3>Last Updated: October 24th, 2024</h3>
<br></br>
<p>
This Privacy Policy explains how Creatimus LLC (“we,” “our,” or “us”) collects, uses, and protects your personal information when you use Hazbot and its associated website. By accessing or using Hazbot, you agree to the collection and use of information in accordance with this policy.
</p>

<h4>1. Information We Collect</h4>
<p>We collect the following types of data to provide and improve Hazbot’s services:</p>
<h5>1.1 Personal Information</h5>
<ul>
<li>Name, email address, phone number (if provided for account setup or communication).</li>
<li>Company or organization information (for business accounts).</li>
</ul>

<h5>1.2 Usage Data</h5>
<ul>
<li>IP address, browser type, device information, and access times when visiting the website.</li>
<li>Data regarding interactions with Hazbot's features (e.g., inputs for hazard analysis or search queries).</li>
</ul>

<h5>1.3 Cookies and Tracking Technologies</h5>
<p>
We use cookies and similar technologies to enhance the user experience, track website traffic, and remember user preferences. You can manage your cookie preferences through your browser settings.
</p>

<h4>2. How We Use Your Information</h4>
<p>We use the collected data to:</p>
<ol>
<li>Provide, operate, and improve Hazbot’s services.</li>
<li>Customize the user experience and develop new features.</li>
<li>Communicate with users about updates, promotions, or technical support.</li>
<li>Analyze website traffic and usage patterns to improve performance.</li>
<li>Ensure compliance with legal obligations and enforce our Terms and Conditions.</li>
</ol>

<h4>3. Sharing Your Information</h4>
<p>
We do not sell or rent personal data to third parties. However, we may share your data in the following circumstances:
</p>
<ol>
<li>With Service Providers: Third-party vendors that assist us with hosting, analytics, and support services.</li>
<li>Legal Compliance: If required to do so by law or to respond to valid legal requests (e.g., subpoenas).</li>
<li>Business Transfers: In case of a merger, sale, or transfer of our assets, your data may be transferred to the new entity.</li>
</ol>

<h4>4. Data Retention</h4>
<p>
We retain personal information for as long as necessary to provide services or as required by law. When no longer needed, data is securely deleted or anonymized.
</p>

<h4>5. Your Data Rights</h4>
<p>
Depending on your location, you may have the following rights:
</p>
<ol>
<li>Access and Correction: Request access to the personal data we hold about you or correct inaccuracies.</li>
<li>Data Deletion: Request deletion of your personal data, subject to legal or contractual obligations.</li>
<li>Data Portability: Obtain a copy of your personal data in a portable format.</li>
<li>Withdraw Consent: If you previously gave consent to data processing, you can withdraw it at any time.</li>
</ol>
<p>
To exercise your rights, please contact us at [Insert Email Address].
</p>

<h4>6. Security Measures</h4>
<p>
We implement technical and organizational measures to protect your data from unauthorized access, disclosure, or alteration. However, no system is entirely secure, and we cannot guarantee the absolute security of your information. If you become aware of any security breach, please notify us immediately at [Insert Email Address].
</p>

<h4>7. Children’s Privacy</h4>
<p>
Hazbot is not intended for use by individuals under the age of 18. We do not knowingly collect personal information from children. If you believe we have collected data from a child, please contact us, and we will promptly delete the information.
</p>

<h4>8. International Data Transfers</h4>
<p>
If you are accessing Hazbot from outside the United States, please note that your data may be transferred to and processed in the United States, where data protection laws may differ from those in your jurisdiction.
</p>

<h4>9. Third-Party Links and Services</h4>
<p>
Our website may contain links to third-party services or websites. We are not responsible for the privacy practices of these external services. Please review their privacy policies before interacting with them.
</p>

<h4>10. Changes to This Privacy Policy</h4>
<p>
We may update this Privacy Policy periodically. When changes are made, we will notify you by posting the updated policy on our website and updating the "Last Updated" date. Your continued use of Hazbot after changes constitutes your acceptance of the revised policy.
</p>

<h4>11. Contact Us</h4>
<p>
If you have any questions about these Terms, please contact us at:<br />
Creatimus LLC<br />
support@creatimus.com
</p>

</Modal.Body>
<Modal.Footer>
<Button variant="primary" onClick={() => setShowPrivacyModal(false)}>
I Accept
</Button>
</Modal.Footer>
</Modal>
</>
);
};

export default SignUpForm;
