import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Form, Dropdown, DropdownMenu, ButtonGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HazbotConversation from 'components/common/hazbot/HazbotConversation';
import HazbotFooter from 'components/common/hazbot/HazbotFooter';
import { useAppContext } from 'providers/AppProvider';
import { useChatWidgetContext } from 'providers/HazbotWidgetProvider';
import { faCircle, faDiceD20, faDownload, faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';
import { BotService } from 'helpers/botService';
import { Button } from 'react-bootstrap';
import { useUserContext } from 'layouts/UserContext';
import { UserService } from 'helpers/userService';
import { useNavigate } from 'react-router-dom';


const MainHazbot = () => {
  const { user, isLoading } = useUserContext();

  if (isLoading || !user) return null;

  const { 
    selectedNeuroskill, 
    setSelectedNeuroskill, 
    selectedModule, 
    setSelectedModule,
    conversation, 
    setConversation
  } = useChatWidgetContext();

  const [botStatus, setBotStatus] = useState({ status: "Unavailable", cssClass: "text-danger" });
  const [modules, setModules] = useState<string[]>([]);

  const neuroskillDisplayNames: Record<string, string> = {
    'hazmat_v1': 'Hazardous Materials',
    //'firefighting_v1': 'Firefighting'
  };

  const moduleDisplayNames: Record<string, string> = {
    'hazmat_general': 'General Hazmat',
    'chemical_properties': 'Chemical Properties',
    'compliance_and_standards': 'Compliance and Standards',
    'decontamination': 'Decontamination',
    'equipment_selection': 'Equipment Selection',
    'hazmat_response': 'Hazmat Response',
    'marine_emergencies': 'Marine Emergencies',
    'risk_assessment': 'Risk Assessment',
    'scenario_based_calculations': 'Scenario-based Calculations',
    'spill_control': 'Spill Control',
    'transport_and_storage': 'Transport and Storage',

    'firefighting_general': 'General Firefighting',
    'firefighting_equipments': 'Equipments',
    'firefighting_fire_supression': 'Fire Supression',
    'firefighting_industrial_fires': 'Industrial Fires',
    'firefighting_marine_fires': 'Marine Fires',
    'firefighting_protective_equipment': 'Protective Equipment',
    'firefighting_safety': 'Safety',
    'firefighting_structural_fires': 'Structural Fires',
    'firefighting_technical_dictionary': 'Technical Dictionary',
    'firefighting_training': 'Training',
    'firefighting_wildland_fires': 'Wildland Fires'
  };

  const [neuroskills, setNeuroskills] = useState<string[]>(['hazmat_v1']); //, 'firefighting_v1']);
  const neuroskillModules: Record<string, string[]> = {
    'hazmat_v1': ['hazmat_general', 'chemical_properties', 'compliance_and_standards', 'decontamination', 'equipment_selection', 'hazmat_response', 'marine_emergencies', 'risk_assessment', 'scenario_based_calculations', 'spill_control', 'transport_and_storage'],
    //'firefighting_v1': ['firefighting_general', 'firefighting_equipments', 'firefighting_fire_supression', 'firefighting_industrial_fires', 'firefighting_marine_fires', 'firefighting_protective_equipment', 'firefighting_safety', 'firefighting_structural_fires', 'firefighting_technical_dictionary', 'firefighting_training', 'firefighting_wildland_fires']
  };

  useEffect(() => {
    const fetchBotStatus = async () => {
      const status = await BotService.getStatus();
      setBotStatus(status);
    };

    fetchBotStatus();
    
    const intervalId = setInterval(fetchBotStatus, 30000);

    return () => clearInterval(intervalId);
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    const fckingKillThem = async () => {
      localStorage.removeItem('hatateToken');
      navigate('/pages/authentication/split/Sign-in');
    };

    const checkUser = async () => {
      try {
        const user = await UserService.getUser();
        console.log("User deletion status: " + user.deleted);
      } catch (error) {
        console.log(error)
        // @ts-ignore
        if (error.response.status === 401) {
          fckingKillThem();
        }
      }
    };

    checkUser();
    const intervalId = setInterval(checkUser, 30000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (selectedNeuroskill in neuroskillModules) {
      const newModules = neuroskillModules[selectedNeuroskill];
      setModules(newModules);
      
      // Check if current selectedModule is valid for the new neuroskill
      if (!newModules.includes(selectedModule)) {
        setSelectedModule(newModules[0]);
      }
    } else {
      setModules([]);
      setSelectedModule(''); // Clear selected module if no valid neuroskill
    }
  }, [selectedNeuroskill, selectedModule, setSelectedModule]);

  const clearChat = () => {
    setConversation({ ...conversation, messages: [] });
  };

//Formateo de fecha para registro y nombre del archivo y wea
  const formatDate = (date: Date): string => {
    if (!(date instanceof Date) || isNaN(date.getTime())) {
      date = new Date();
    }
  
    const pad = (num: number): string => num.toString().padStart(2, '0');
    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());
    
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };
  
  const parseDate = (time?: string | number | Date): Date => {
    try {
      if (!time) return new Date();
      if (time instanceof Date) {
        return isNaN(time.getTime()) ? new Date() : time;
      }
      if (typeof time === 'number') {
        const date = new Date(time);
        return isNaN(date.getTime()) ? new Date() : date;
      }
      const date = new Date(time);
      return isNaN(date.getTime()) ? new Date() : date;
    } catch (error) {
      return new Date();
    }
  };
  
  // Función para exportar a TXT
  const exportToTxt = () => {
    const text = conversation.messages
      .map(msg => {
        const msgAuthor = msg.type === 'sent' ?  user.name + ' ' + user.lastName : 'HAZBOT';
        const timestamp = formatDate(parseDate(msg.time));
        return `[${timestamp}] ${msgAuthor}: ${msg.message}`;
      })
      .join('\n\n');
  
    const blob = new Blob([text], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    const fileDate = formatDate(new Date());
    a.download = `HAZBOT_chatsession-${fileDate}.txt`;
    a.href = url;
    a.click();
    URL.revokeObjectURL(url);
  };

  // Función para exportar a JSON
  const exportToJson = () => {
    const messages = conversation.messages.map(msg => {
      const timestamp = formatDate(parseDate(msg.time));
      return {
        timestamp: timestamp,
        sender: (msg.type === 'sent' ? user.name + ' ' + user.lastName : 'HAZBOT'),
        content: msg.message
      };
    });

    const data = JSON.stringify(messages, null, 2);
    const blob = new Blob([data], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    const fileDate = formatDate(new Date());
    a.href = url;
    a.download = `HAZBOT_chatsession-${fileDate}.json`;
    a.click();
    URL.revokeObjectURL(url);
  };

  // Función para exportar a CSV
  const exportToCsv = () => {
    const headers = ['sender', 'content', 'timestamp'];
    const csvRows = [headers];
    
    conversation.messages.forEach(msg => {
      if (!msg.message) return;
      // remap aqui tmb
      const msgAuthor = msg.type === 'sent' ? user.name + ' ' + user.lastName : 'HAZBOT';
      const timestamp = formatDate(parseDate(msg.time));
      csvRows.push([
        msgAuthor,
        `"${msg.message.replace(/"/g, '""')}"`,
        timestamp
      ]);
    });

    const csvContent = csvRows.map(row => row.join(',')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    const fileDate = formatDate(new Date());
    a.href = url;
    a.download = `HAZBOT_chatsession-${fileDate}.csv`;
    a.click();
    URL.revokeObjectURL(url);
  };

  return (
    <>
      <Row className="g-2 mb-1">
        <Col xs={6} className='bt-1'>
          <Form.Group>
            <Form.Label>Select Neuroskill:</Form.Label>
              <Form.Control 
                as="select" 
                value={selectedNeuroskill}  
                onChange={(e) => setSelectedNeuroskill(e.target.value)}
              >
                {Array.isArray(neuroskills) && neuroskills.length > 0 ? (
                  neuroskills.map((skill) => (
                    <option key={skill} value={skill}>
                      {neuroskillDisplayNames[skill] || skill}
                    </option>
                  ))
                ) : (
                  <option>No neuroskills available</option>
                )}
              </Form.Control>
          </Form.Group>
        </Col>
        <Col xs={6} className='bt-1'>
          <Form.Group>
            <Form.Label>Select Module:</Form.Label>
            <Form.Control 
              as="select" 
              value={selectedModule} 
              onChange={(e) => setSelectedModule(e.target.value)}
            >
              {modules.map((module) => (
                <option key={module} value={module}>
                  {moduleDisplayNames[module] || module}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
      </Row>

      <Row className="g-1">
        <Col xs={12} xxl={12} xg={12} xl={12}>
          <Card className="bg-body-emphasis chat"> {/* height:'200%'}}> */}
            <Card.Header className="d-flex flex-between-center px-4 py-2 border-bottom">
              <h6 className="mb-0 d-flex align-items-center gap-2">
                HAZBOT is {botStatus.status}
                <FontAwesomeIcon icon={faCircle} className={`${botStatus.cssClass} fs-11`} />
                <div>{/* contador tokens aca */}</div>
              </h6>
              <div className="d-flex align-items-center gap-2">
                <Button variant="phoenix-secondary" className="p-0 border-0" title="Reset Chat" onClick={clearChat} style={{ "--phoenix-btn-bg": "unset" } as React.CSSProperties} 
                >
                  <label
                    className="text-body-quaternary fs-9 cursor-pointer"
                    htmlFor="widgetImages"
                  >
                    <FontAwesomeIcon icon={faTrash} transform="down-1" size='lg' />
                  </label>
                </Button>

                <Dropdown as={ButtonGroup} className="p-0 border-0" title="Export Chat"
                >
                  <Dropdown.Toggle split variant="phoenix-secondary" as={Button} className="p-0 border-0" style={{ "--phoenix-btn-bg": "none" } as React.CSSProperties}>
                    <FontAwesomeIcon 
                      icon={faDownload} 
                      className="text-body-quaternary fs-7"
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={exportToTxt}>
                      Export as TXT
                    </Dropdown.Item>
                    <Dropdown.Item onClick={exportToJson}>
                      Export as JSON
                    </Dropdown.Item>
                    <Dropdown.Item onClick={exportToCsv}>
                      Export as CSV
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>

            </Card.Header>
            <Card.Body className="scrollbar p-2">
              <HazbotConversation />
            </Card.Body>
            <Card.Footer className="border-top ps-1 pe-4 py-2">
              <HazbotFooter />
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default MainHazbot;