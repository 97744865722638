import hazbotLogo from 'assets/img/team/40x40/Hazball.png';

import { FileAttachment } from 'components/common/AttachmentPreview';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faFaceSmile,
  faPenToSquare,
  faReply,
  faShare,
  faTrash
} from '@fortawesome/free-solid-svg-icons';

export interface Message {
  id: number;
  type: 'sent' | 'received';
  message?: string;
  time: string;
  readAt: Date | string | null;
  attachments?: { images?: string[]; file?: FileAttachment };
  seen?: boolean;
}
export interface User {
  id: number;
  avatar?: string;
  status: 'online' | 'offline';
  name: string;
}
export interface Conversation {
  id: number;
  user: User;
  messages: Message[];
  unreadMessages?: number;
}

export type MessageActionType = {
  icon: IconProp;
  label: string;
};

export const supportChat: Conversation = {
  id: 1,
  user: { id: 1, avatar: hazbotLogo, status: 'online', name: 'TestBot' },
  messages: []
};

export const suggestions: string[] = [
  'What kind of support do you offer?',
  'What are your core areas of expertise?',
  'I need information on OSHA 1910.120',
];

export const files: FileAttachment[] = [
  {
    name: 'Federico_salsaniuella_godarf_design.zip',
    size: '53.34 MB',
    date: 'Dec 8, 2011',
    format: 'zip'
  },
  {
    name: 'Restart_lyf.bat',
    size: '11.13 KB',
    date: 'Dec 2, 2011',
    format: 'bat'
  },
  {
    name: 'Fake lorem ipsum fr fr.txt',
    size: '11.13 KB',
    date: 'Dec 2, 2011',
    format: 'txt'
  },
  {
    name: 'Unsupported file format.mad',
    size: '11.13 KB',
    date: 'Dec 2, 2011',
    format: 'mad'
  }
];

export const actions: MessageActionType[] = [
  {
    icon: faTrash,
    label: 'Delete'
  },
  {
    icon: faReply,
    label: 'Reply'
  },
  {
    icon: faPenToSquare,
    label: 'Edit'
  },
  {
    icon: faShare,
    label: 'Share'
  },
  {
    icon: faFaceSmile,
    label: 'Emoji'
  }
];
