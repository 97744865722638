import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/base/Button';
import { useAppContext } from 'providers/AppProvider';
import { Offcanvas } from 'react-bootstrap';
import NavigationType from './NavigationType';
import HorizontalNavbarShape from './HorizontalNavbarShape';
import ColorScheme from './ColorScheme';
import VerticalNavbarAppearance from './VerticalNavbarAppearance';
import TopNavbarAppearance from './TopNavbarAppearance';
import RTLMode from './RTLMode';
import { useSettingsPanelContext } from 'providers/SettingsPanelProvider';
import {
  faArrowsRotate,
  //faPalette,
  //faTimes
} from '@fortawesome/free-solid-svg-icons';
import ChatWidgetVisibility from './ChatWidgetVisibility';
import { RESET } from 'reducers/ConfigReducer';
import { IconPack, IconProp } from '@fortawesome/fontawesome-svg-core';

const SettingsPanel = () => {
  const { configDispatch } = useAppContext();

  const {
    settingsPanelConfig: { openSettingPanel, disableResetButton },
    setSettingsPanelConfig
  } = useSettingsPanelContext();

  const handleClose = () => {
    setSettingsPanelConfig({
      openSettingPanel: !openSettingPanel
    });
  };

  const handleResetToDefault = () => {
    if (!disableResetButton) {
      configDispatch({
        type: RESET
      });
    }
  };

  return (
    <Offcanvas
      show={openSettingPanel}
      onHide={handleClose}
      placement="end"  // Change this to "start", "end", "top", or "bottom" as needed
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Settings</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <NavigationType />
        <HorizontalNavbarShape />
        <ColorScheme />
        <VerticalNavbarAppearance />
        <TopNavbarAppearance />
        <RTLMode />
        <ChatWidgetVisibility />

        <Button onClick={handleResetToDefault} disabled={disableResetButton}>
          <FontAwesomeIcon icon={faArrowsRotate as IconProp} /> Reset to Default
        </Button>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default SettingsPanel;

