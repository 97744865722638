import React from 'react';
import { IUser } from 'data/models/user/IUser';

interface UserContextType {
  user: IUser | null;
  isLoading: boolean;
  error: string | null;
  refetchUser: () => Promise<void>;
}

export const UserContext = React.createContext<UserContextType>({
  user: null,
  isLoading: true,
  error: null,
  refetchUser: async () => {}
});

export const useUserContext = () => React.useContext(UserContext);