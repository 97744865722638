import { Feature } from 'data/landing/alternate-landing-data';
import { Col, Row } from 'react-bootstrap';

interface FeatureSectionProps {
  feature: Feature;
  isLast: boolean;
}

const FeatureSection = ({ feature, isLast }: FeatureSectionProps) => {
  return (
    <div className="feature-section d-flex flex-column align-items-center text-center text-md-start">
      {/* Image at the top for desktop, maintain position for mobile */}
      <div className="feature-img-wrapper mb-4">
      <img
  src={feature.lightImg}
  alt=""
  className="w-100 d-dark-none mb-3"
  style={{
    width: '300px',
    height: '300px',
    objectFit: 'contain',
  }}
/>
<img
  src={feature.darkImg}
  alt=""
  className="w-100 d-light-none mb-3"
  style={{
    width: '300px',
    height: '300px',
    objectFit: 'contain',
  }}
/>

</div>

      {/* Title and Description below the image */}
      <h4 className="feature-title mb-3">{feature.title}</h4>
      <div
        className="feature-description"
        dangerouslySetInnerHTML={{ __html: feature.description }}
      />
    </div>
  );
};

export default FeatureSection;
