/* @v2.0 - agregamos contador tokens */
import axios from 'axios';
import { apiUrl } from './utils';

export const BotService = {
  isOnline: async () => {
    const headers = { 'Content-Type': 'application/json' };
    try {
      const response = await axios.post(`${apiUrl}bot/status`, { headers });
      const botStatus = response.data.status;
      return botStatus === 'online' ? true : false;
    } catch (error: any) {
      return false;
    }
  },

  getStatus: async () => {
    const headers = { 'Content-Type': 'application/json' };
    try {
      const response = await axios.get(`${apiUrl}bot/status`, { headers });
      const botStatus = response.data.status; // online / offline
      return {
        status: botStatus === 'online' ? "Available" : "Unavailable",
        cssClass: botStatus === 'online' ? "text-success" : "text-danger"
      };
    } catch (error: any) {
      return {
        status: "Unavailable",
        cssClass: "text-danger"
      };
    }
  },

  getTokens: async () => {
    const headers = { 'Content-Type': 'application/json' };
    try {
      const response = await axios.get(`${apiUrl}bot/tokens`, { headers });
      return response.data.availableTokens || 0;
    } catch (error: any) {
      console.error('Error fetching tokens:', error);
      return 0;
    }
  }
};

/* @v1.0 - init 
import axios from 'axios';
import { apiUrl } from './utils';

export const BotService = {
  isOnline: async () => {
    const headers = { 'Content-Type': 'application/json' };
    try {
      const response = await axios.post(`${apiUrl}bot/status`, { headers });
      const botStatus = response.data.status;
      return botStatus === 'online' ? true : false;
    } catch (error: any) {
      return false;
    }
  },
  getStatus: async () => {
    const headers = { 'Content-Type': 'application/json' };
    try {
      const response = await axios.get(`${apiUrl}bot/status`, { headers });
      const botStatus = response.data.status; // online / offline
      
      return {
        status: botStatus === 'online' ? "Available" : "Unavailable",
        cssClass: botStatus === 'online' ? "text-success" : "text-danger"
      };
    } catch (error: any) {
      return {
        status: "Unavailable",
        cssClass: "text-danger"
      };
    }
  }
}; */