import React from 'react';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import Button from 'components/base/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom'; // Make sure to import Link
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faBell,
  faBullhorn,
  faCalendarXmark,
  faCircleNodes,
  faFileInvoiceDollar,
  faPeace,
  faPieChart,
  faSliders,
  faTruckFast
} from '@fortawesome/free-solid-svg-icons';
import { PageBreadcrumbItem } from 'components/common/PageBreadcrumb';

export const faqBreadcrumbItems: PageBreadcrumbItem[] = [
  {
    label: 'Pages',
    url: '#!'
  },
  {
    label: 'Faq',
    active: true
  }
];
export interface Faq {
  id: number;
  title: string;
  details: string;
}

export const faqs: Faq[] = [
  {
    id: 1,
    title: 'What’s your return policy?',
    details: `At the time of shipment, we assure that your product will be free
	of defects in materials and workmanship and will conform to the
	specifications outlined on the lot-specific datasheet included
	with the product. Please contact our technical support services if
	you have a technical issue with a product: Email: 
	<a href="mailto:phoenix@email.com">phoenix@support.com</a>. If the team concludes that the product does not adhere to the
	requirements mentioned on the datasheet, we will provide a free
	replacement or a full refund of the product's invoice price.`
  },
  {
    id: 2,
    title: `I ordered the wrong product. What should I do?`,
    details: `We would consider accepting the return of the merchandise, subject to an 20% restocking fee plus any shipping and handling fees. The customer is liable for shipping costs for both the returned product and the new replacement product, both to and from our facility. All returns require prior authorisation from us and must be mailed back to us within seven business days of receiving the goods. Products must be returned in the same or equivalent packing (i.e., cold and insulated) in which they were shipped (i.e., cold and insulated). Once we get the item, we will ship out the replacement item.`
  },
  {
    id: 3,
    title: `How do I cancel my order?`,
    details: `If you must cancel your order, please call <a href="tel:+871406-7509">(871) 406-7509</a> Please note that we attempt to process and dispatch orders the same day (if received before 1pm PST), and once your product has shipped, our return policy will apply.`
  },
  {
    id: 4,
    title: `What are your shipping & handling charges?`,
    details: `Our handling fee is a flat rate of $20. The shipping costs vary depending on your location and the items you've purchased. For an exact shipping cost estimate, please proceed through the checkout process and enter your items and address.`
  },
  {
    id: 6,
    title: `Do you accept purchase orders?`,
    details: `Yes. Please include your purchase order number at online checkout, or clearly label it on a faxed or emailed purchase order. Please give the relevant email or mailing address for your accounts payable department so that the invoice can be sent to the appropriate location.`
  }
];

export interface FaqType {
  question: string;
  answer: string | JSX.Element;
}

export interface FaqCategory {
  id: string;
  name: string;
  icon: IconProp;
  description: string;
  category?: string;
  topFaqs: FaqType[];
  faqs: FaqType[];
}

export const faqCategories: FaqCategory[] = [
  {
    id: 'sale-101',
    name: 'FAQ',
    icon: faPieChart,
    description:
      'Find quick answers to common questions about Hazbot AI.',
    category: 'popular',
    topFaqs: [
      {
        question: 'Frequently Asked Questions',
        answer:
          'Our FAQ section covers the essentials to help you get the most out of Hazbot.'
      },
    ],
    faqs: [
      {
        question: 'How do I purchase a subscription?',
        answer: (
          <>
            Visit our <Link to="../../pages/authentication/split/Sign-Up">Sign-Up</Link> section to get started. 
            For Enterprise Solutions, <Link to="mailto:support@hazbot-ai.com">Contact Us </Link>for a tailored approach that meets your specific needs.
          </>
        ),
      },
      {
        question: 'Is my data secure with Hazbot AI?',
        answer:
          'Yes, we take data security very seriously. We implement robust security measures to ensure your data is protected and handled in accordance with our privacy policy.'
      },
      {
        question: 'Can I change my subscription plan later?',
        answer:
          'Yes, you can upgrade or downgrade your subscription plan at any time. Simply visit your account settings to manage your subscription.'
      },
      {
        question: 'What payment methods do you accept?',
        answer:
        'We accept all major credit cards. Please check our payment options on the checkout page for more details.'
      },
      {
        question: 'How can I contact customer support?',
        answer: (
          <>
           You can reach our customer support team via the "Get in Touch" section on our website or by emailing  <Link to="mailto:support@hazbot-ai.com">support@hazbot-ai.com</Link>
          </> 
        ),
      },
      {
        question: 'What do I do if I encounter an error while using the service?',
        answer:
          'If you experience any errors, please clear your browser cache and try again. If the issue persists, contact our support team for assistance.'
      }
    ]
  },
  {
    id: 'delivery-101',
    name: 'How to Ask',
    icon: faTruckFast,
    description:
      'This section offers examples of how to frame clear and effective questions to receive optimal responses from Hazbot AI.',
    topFaqs: [
      {
        question: 'Sample Questions for Effective Responses',
        answer:
          'The following are examples of well-structured queries and key phrases that Hazbot understands. Whether you are seeking chemical risk analysis, leak response guidance, or regulatory compliance information, following these guidelines will help you receive accurate and actionable insights.'
      },

    ],
    faqs: [
      {
        question:
          'REGULATIONS AND STANDARDS',
        answer:
          'What are the specific U.S. regulations for handling, transporting, and storing hazardous materials? I would like information about key regulations, such as those issued by the Department of Transportation (DOT) under 49 CFR and the Environmental Protection Agency (EPA), as well as requirements for the classification, labeling, transportation, and emergency response of hazardous materials. Also, what government agencies oversee compliance with these regulations, and what penalties or fines are imposed for noncompliance? '
      },
      {
        question: 'EMERGENCY RESPONSE',
        answer:
          'What are the correct steps to safely transfer styrene monomer from a damaged tank to a receiving tank? Please include information on polymerization hazards, possible heat generation, and tank inerting techniques to prevent hazardous reactions, as per industrial safety regulations. '
      },
      {
        question: 'TANKS FOR THE TRANSPORTATION AND STORAGE OF CHEMICALS',
        answer:
          'What construction standards are used in the United States to design and manufacture tank trucks that transport cryogenic liquids? I am interested in knowing the regulations issued by ASME (American Society of Mechanical Engineers) under the ASME Code Section VIII, and also the DOT regulations (49 CFR Part 178) for the transportation of cryogenics. Also, what materials, pressure requirements and safety systems must these cargo tanks include, and what certification tests are necessary to ensure compliance?'
      },
      {
        question: 'HAZARDOUS WASTE DISPOSAL',
        answer:
          'What are the appropriate procedures for the safe disposal of sodium cyanide waste according to EPA regulations and international hazardous waste management standards? Please include details on pre-treatment, safe transportation, and certified facilities where these wastes should be deposited to minimize environmental and human health risks.'
      },
      {
        question: 'CALCULATIONS',
        answer:
          'How long does it take to empty a 300-ton sodium hydroxide tank, due to a leak at the bottom of the tank? Additional information: Product density, tank dimensions, atmospheric pressure, ambient temperature, leak height, location or size of leak (2-inch discharge valve).'
      },
      {
        question: 'CHEMICALS',
        answer:
          'What chemical reaction occurs when ethyl bromide and hydrochloric acid are mixed, and what are the consequences and products of this reaction? I would like to understand the risks associated with this mixture, such as the possible release of toxic or corrosive gases, as well as the recommended safety measures for handling these compounds?'
      },
      {
        question: 'MARINE EMERGENCIES',
        answer:
          'What are the alternatives to contain and recover a 30 tons spill of Bunker 6 at sea, considering its high viscosity and difficulty of handling? I am interested in knowing what types of chemical dispersants are effective for this substance, and what environmental conditions (water temperature, current) should be considered. Also, what international regulations and local standards should be met for the use of dispersants in marine areas, and what long-term environmental impacts should be anticipated?'
      },
      {
        question: 'PPE and RPE',
        answer:
          'What is the safest procedure to neutralize soil contaminated with ethylene oxide, considering its toxicity and volatile properties? I would like to know what neutralizing agents are recommended, the applicable regulations, and the remediation techniques that should be used to reduce the impact of the release of this compound into the environment. Also, what chemical and respiratory protection equipment is necessary to carry out this type of operation safely?'
      }
    ]
  },
  {
    id: 'notification-101',
    name: 'Video Tutorials',
    icon: faBell,
    description:
      'Dive into our comprehensive video tutorials and walkthroughs designed to help you navigate and maximize your experience with Hazbot AI.',
    topFaqs: [
    
      {
        question: 'Visual Walkthroughs',
        answer:
          'Our step-by-step guides provide clear instructions and visual demonstrations to enhance your understanding and usage of the platform. Join us as we simplify the process of leveraging Hazbot AI’s capabilities to meet your needs.'
      }
    ],
    faqs: [
      {
        question: 'How to get the most accurate response',
        answer:
        (
          <div>
  <p>Learn how to get the most accurate response from Hazbot AI with this video tutorial:</p>
  <iframe 
    style={{
      width: window.innerWidth >= 768 ? '80%' : '100%', // Adjust width based on screen size
      height: window.innerWidth >= 768 ? '450px' : '315px', // Adjust height for larger screens
      border: 'none' // Optional: remove border
    }} 
    src="https://www.youtube.com/embed/2iGoFq8LYEI" 
    title="YouTube video player" 
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
    allowFullScreen>
  </iframe>
</div>
        ),
      },
    ]
  },
  {
 id: 'order-101',
    name: 'Documentation',
    icon: faFileInvoiceDollar,
    description: "Welcome to Hazbot's Documentation Section.",
    topFaqs: [
      {
        question: "Getting Started",
        answer: (
          <div style={{
            width: '100%', // or a fixed width, e.g., '800px'
            maxWidth: '800px', // maximum width of the container
            height: 'auto', // adjust based on content
            overflow: 'auto', // allows scrolling if content exceeds the height
            padding: '20px', // padding for aesthetics
            boxSizing: 'border-box' // ensures padding doesn't affect overall size
          }}>
            <h3 style={{ margin: '0 0 10px', lineHeight: '1.5' }}>I. Calculations</h3>
            <p style={{ margin: '0 0 10px', lineHeight: '1.5' }}>
              HAZBOT leverages advanced calculation libraries for various technical analyses.
            </p>
            <h4 style={{ margin: '0 0 10px', lineHeight: '1.5' }}>
              TECHNICAL ANALYSIS - ADVANCED CALCULATION LIBRARIES AVAILABLE FOR HAZBOT
            </h4>
            <h4 style={{ margin: '0 0 10px', lineHeight: '1.5' }}>CHEMPY (CHEMISTRY PYTHON)</h4>
            <strong>Key Capabilities:</strong>
            <ul style={{ paddingLeft: '20px', listStyleType: 'disc', margin: '0 0 10px' }}>
              <li style={{ lineHeight: '1.5' }}>
                <strong>Stoichiometry:</strong> Automatic balancing of chemical equations.
              </li>
              <li style={{ lineHeight: '1.5' }}>
                <strong>Thermodynamics:</strong> Gibbs calculations of enthalpy, entropy, and free energy.
              </li>
              <li style={{ lineHeight: '1.5' }}>
                <strong>Chemical kinetics:</strong> Modeling of reactions and calculation of velocities.
              </li>
              <li style={{ lineHeight: '1.5' }}>
                <strong>Chemical equilibrium:</strong> Determination of equilibrium constants.
              </li>
            </ul>
            <strong>Advanced Applications:</strong>
            <ul style={{ paddingLeft: '20px', listStyleType: 'disc', margin: '0 0 10px' }}>
              <li style={{ lineHeight: '1.5' }}>Simulation of reactions in the gas phase and solution.</li>
              <li style={{ lineHeight: '1.5' }}>Chemical speciation analysis.</li>
              <li style={{ lineHeight: '1.5' }}>pH and solubility calculations.</li>
            </ul>

            <h4 style={{ margin: '0 0 10px', lineHeight: '1.5' }}>PINT (PHYSICAL QUANTITIES)</h4>
            <p><strong>Key Capabilities:</strong></p>
            <ul style={{ paddingLeft: '20px', listStyleType: 'disc', margin: '0 0 10px' }}>
              <li style={{ lineHeight: '1.5' }}>
                <strong>Unit Manipulation:</strong> Allows calculations to be performed with physical units.
              </li>
              <li style={{ lineHeight: '1.5' }}>
                <strong>Unit conversion:</strong> Facilitates the transformation between measurement systems.
              </li>
              <li style={{ lineHeight: '1.5' }}>
                <strong>Dimensional verification:</strong> Ensures consistency in physical calculations.
              </li>
            </ul>
            <p><strong>Advanced Applications:</strong></p>
            <ul style={{ paddingLeft: '20px', listStyleType: 'disc', margin: '0 0 10px' }}>
              <li style={{ lineHeight: '1.5' }}>Propagation of uncertainties in physical calculations.</li>
              <li style={{ lineHeight: '1.5' }}>Definition of customized unit systems.</li>
              <li style={{ lineHeight: '1.5' }}>NumPy integration for vector calculations with units.</li>
            </ul>

            <h4 style={{ margin: '0 0 10px', lineHeight: '1.5' }}>SCIPY (SCIENTIFIC PYTHON)</h4>
            <p><strong>Key Capabilities:</strong></p>
            <ul style={{ paddingLeft: '20px', listStyleType: 'disc', margin: '0 0 10px' }}>
              <li style={{ lineHeight: '1.5' }}>
                <strong>Optimization:</strong> Implements advanced algorithms such as BFGS, Nelder-Mead, and Powell.
              </li>
              <li style={{ lineHeight: '1.5' }}>
                <strong>Numerical integration:</strong> Offers adaptive methods such as quad and Romberg.
              </li>
              <li style={{ lineHeight: '1.5' }}>
                <strong>Linear Algebra:</strong> Provides optimized LAPACK routines.
              </li>
              <li style={{ lineHeight: '1.5' }}>
                <strong>Fourier Transforms:</strong> Implements efficient FFT and its variants.
              </li>
              <li style={{ lineHeight: '1.5' }}>
                <strong>Signal processing:</strong> Includes FIR/IIR filters and window design.
              </li>
            </ul>
            <p><strong>Advanced Applications:</strong></p>
            <ul style={{ paddingLeft: '20px', listStyleType: 'disc', margin: '0 0 10px' }}>
              <li style={{ lineHeight: '1.5' }}>
                Resolution of EDOs/EDPs with <code>'scipy.integrate.ode'</code> and <code>'scipy.integrate.solve_ivp'</code>.
              </li>
              <li style={{ lineHeight: '1.5' }}>
                Multi-dimensional interpolation with <code>'scipy.interpolate'</code>.
              </li>
              <li style={{ lineHeight: '1.5' }}>
                Statistics and hypothesis testing with <code>'scipy.stats'</code>.
              </li>
            </ul>

            <h4 style={{ margin: '0 0 10px', lineHeight: '1.5' }}>SYMPY (SYMBOLIC PYTHON)</h4>
            <p><strong>Key Capabilities:</strong></p>
            <ul style={{ paddingLeft: '20px', listStyleType: 'disc', margin: '0 0 10px' }}>
              <li style={{ lineHeight: '1.5' }}>
                <strong>Symbolic manipulation:</strong> Allows complex algebraic operations.
              </li>
              <li style={{ lineHeight: '1.5' }}>
                <strong>Differential and Integral Symbolic Calculus:</strong> Derivation, integration, and limits.
              </li>
              <li style={{ lineHeight: '1.5' }}>
                <strong>Solving equations:</strong> Symbolic solver for systems of equations.
              </li>
              <li style={{ lineHeight: '1.5' }}>
                <strong>Series and expansions:</strong> Taylor, Laurent, and other series.
              </li>
            </ul>
            <p><strong>Advanced Applications:</strong></p>
            <ul style={{ paddingLeft: '20px', listStyleType: 'disc', margin: '0 0 10px' }}>
              <li style={{ lineHeight: '1.5' }}>
                Automatic simplification of expressions with <code>'sympy.simplify'</code>.
              </li>
              <li style={{ lineHeight: '1.5' }}>
                Tensor calculus with <code>'sympy.tensor'</code>.
              </li>
              <li style={{ lineHeight: '1.5' }}>
                Group theory with <code>'sympy.combinatorics'</code>.
              </li>
            </ul>

            <h4 style={{ margin: '0 0 10px', lineHeight: '1.5' }}>NUMPY (NUMERICAL PYTHON)</h4>
            <p><strong>Key Capabilities:</strong></p>
            <ul style={{ paddingLeft: '20px', listStyleType: 'disc', margin: '0 0 10px' }}>
              <li style={{ lineHeight: '1.5' }}>
                <strong>Vectorized operations:</strong> Enables efficient calculations on large data sets.
              </li>
              <li style={{ lineHeight: '1.5' }}>
                <strong>Linear Algebra:</strong> Includes optimized matrix operations.
              </li>
              <li style={{ lineHeight: '1.5' }}>
                <strong>Random Number Generation:</strong> Offers multiple probabilistic distributions.
              </li>
              <li style={{ lineHeight: '1.5' }}>
                <strong>Fourier Transforms:</strong> Efficient FFT Implementation.
              </li>
            </ul>
            <p><strong>Advanced Applications:</strong></p>
            <ul style={{ paddingLeft: '20px', listStyleType: 'disc', margin: '0 0 10px' }}>
              <li style={{ lineHeight: '1.5' }}>
                Memory management and operations with structured arrays.
              </li>
              <li style={{ lineHeight: '1.5' }}>
                Data fitting using <code>'numpy.polyfit'</code>.
              </li>
              <li style={{ lineHeight: '1.5' }}>
                Monte Carlo simulations with random sampling methods.
              </li>
             <p></p><Link to="/hazbot/getting-started" style={{ textDecoration: 'none' }}>
  <Button
    variant="outline-primary"
    endIcon={<FontAwesomeIcon icon={faAngleRight} transform="down-1" />}
  >
    See more
  </Button>
</Link>

            </ul>
          </div> ),
      },
    ],
    faqs: [],
  }
  
];
