import AuthSplitLayout from 'layouts/AuthSplitLayout';
import bgVideo from 'assets/video/Animation.mp4';
import bg from 'assets/img/bg/hbtrans.png';
import SignInForm from 'components/modules/auth/SignInForm';
import { useNavigate } from 'react-router-dom';
import { getHatateToken } from 'helpers/utils';
import { useEffect } from 'react';

const SignIn = () => {
  const navigate = useNavigate();
  
  useEffect(() => {
    const token = getHatateToken();
    if (token) {
      //navigate('https://login.hazbot-ai.com/hazbot_vSignIn.tsx'); // Token exists, redirect to home
      navigate('/'); // Token exists, redirect to home
    }
  }, [navigate]);

  return (
    <AuthSplitLayout bg="assets/img/bg/hbtrans.png" bgVideo={bgVideo}>
      <SignInForm layout="split" />
    </AuthSplitLayout>
  );
};

export default SignIn;
